/* Ensure normal navbar style remains unchanged */
.navbar {
  position: relative;
}

/* Offcanvas menu styles */
.offcanvas {
  width: 250px; /* Set width for the offcanvas menu */
}

.offcanvas-body {
  padding: 20px; /* Add padding for offcanvas content */
}

.offcanvas-header .btn-close {
  filter: invert(1); /* Change the close button color to white */
}

.navbar-nav {
  flex-direction: column; /* Stack nav items vertically */
}

.nav-item {
  text-align: center; /* Center text in nav items */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
  }
}
