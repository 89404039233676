.App {
  text-align: center;
}

body {
  background-color: #ffffff; 
  margin: 0; 
  padding: 0; 
  overflow-x: hidden; 
}

.section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  
  padding: 20px;
  box-shadow: 0 8px 20px rgba(0, 6, 90, 0.9);
  border: 3px solid #000000; 
}















