.oxygen-light {
    font-family: "Oxygen", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .oxygen-regular {
    font-family: "Oxygen", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .oxygen-bold {
    font-family: "Oxygen", sans-serif;
    font-weight: 700;
    font-style: bold;
  }